import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import presenting from '../images/img/presenting2.jpg';
import attendees  from '../images/img/attendees1a.jpg';
import diagram    from '../images/img/diagram1.jpg';
import emoji      from '../images/img/emoji5a.jpg';
import jon        from '../images/img/jon1.jpg';
import { CONTENT_WIDTH, MIN_WIDTH } from '../functions/global';

const presentingAnim = () => {
}
const Presenting = () => {
  useEffect( () => {
    presentingAnim();
  }, [] );
  return (
    <div css={ css`
      background-color: #ffffff;
      ${'' /* padding-top: 6rem; */}
    `}>
      <div
        css={ css`
          ${'' /* border: 1px solid red; */}
          margin: 0rem auto 0rem;
          max-width: 90vw;
          width: ${CONTENT_WIDTH}px;
          ${'' /* max-height: 600px;
          height: calc( 100vh - 5rem ); */}
          overflow: hidden;
          @media (max-width: ${MIN_WIDTH}px) {
            width: auto;
          }
        `}
      >
        <svg viewBox="0 0 1083 600">
          <rect x={0} y={0} width={1083} height={625}
            css={css`fill:#ffffff;`}
          />
          <image x={350} y={25} width={710} xlinkHref={presenting} />
          <image x={350} y={450} width={710} xlinkHref={attendees} />
          <image x={25} y={25} width={300} xlinkHref={diagram} />
          <image x={25} y={209} width={300} xlinkHref={emoji} />
          <image x={25} y={405} width={300} xlinkHref={jon} />
        </svg>
      </div>
      <div
        css={css`
          text-align: right;
          font-size: 12px;
          margin: 0rem auto 0rem;
          max-width: 90vw;
          width: ${CONTENT_WIDTH}px;
          color: #555555;
        `}
      >*My hosted tech talks</div>
    </div>
  );
}
export default Presenting;
