import React from 'react';
import { css } from '@emotion/react';
// see ../functions/seeMoreAnim.js for animation code

const c1 = css`fill: #aaaaaa; opacity: 0;`

const SeeMore = () => {
  return (
    <svg width={100} height={100} viewBox="0 0 448 451" fill="none">
      {/* <circle cx={228} cy={231} r={220} fill="#C4C4C4" />
      <circle cx={220} cy={220} r={219} fill="white" stroke="black" stroke-width={2} /> */}
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M127 88.7068C127 85.9658 128.044 83.2138 130.149 81.1138C134.343 76.9198 141.13 76.9198 145.324 81.1138L219.671 155.466L294.023 81.1138C298.217 76.9198 305.01 76.9198 309.198 81.1138C313.392 85.3078 313.392 92.1008 309.198 96.2938L227.264 178.228C223.07 182.422 216.277 182.422 212.089 178.228L130.15 96.2938C128.05 94.2008 127 91.4538 127 88.7068Z"
          css={c1}
          className="chevron"
          id="chevron1"
        />
      </g>
      <g clipPath="url(#clip1_0_1)">
        <path
          d="M127 180.707C127 177.966 128.044 175.214 130.149 173.114C134.343 168.92 141.13 168.92 145.324 173.114L219.671 247.466L294.023 173.114C298.217 168.92 305.01 168.92 309.198 173.114C313.392 177.308 313.392 184.101 309.198 188.294L227.264 270.228C223.07 274.422 216.277 274.422 212.089 270.228L130.15 188.294C128.05 186.201 127 183.454 127 180.707Z"
          css={c1}
          className="chevron"
          id="chevron2"
        />
      </g>
      <g clipPath="url(#clip2_0_1)">
        <path
          d="M127 272.707C127 269.966 128.044 267.214 130.149 265.114C134.343 260.92 141.13 260.92 145.324 265.114L219.671 339.466L294.023 265.114C298.217 260.92 305.01 260.92 309.198 265.114C313.392 269.308 313.392 276.101 309.198 280.294L227.264 362.228C223.07 366.422 216.277 366.422 212.089 362.228L130.15 280.294C128.05 278.201 127 275.454 127 272.707Z"
          css={c1}
          className="chevron"
          id="chevron3"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect width="185.343" height="185.343" fill="white" transform="translate(312.343 37) rotate(90)"/>
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect width="185.343" height="185.343" fill="white" transform="translate(312.343 129) rotate(90)"/>
        </clipPath>
        <clipPath id="clip2_0_1">
          <rect width="185.343" height="185.343" fill="white" transform="translate(312.343 221) rotate(90)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
export default SeeMore;
