import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import laptopImg from '../images/img/laptop8.png';
import screenshot1 from '../images/img/screenshot1a.png';
import screenshot2 from '../images/img/screenshot2.png';
import screenshot4 from '../images/img/screenshot4a.png';
import screenshot5 from '../images/img/screenshot5a.png';
import LaptopBkg from './laptopBkg';
import { CONTENT_WIDTH, MIN_WIDTH } from '../functions/global';

const showLaptop = true;
gsap.registerPlugin(ScrollTrigger);

const screenshotFadeIn = () => {
  const tl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: "#laptopTrigger",
      start: "50% bottom",
      end: "bottom top",
      markers: false,
      toggleActions: "play pause resume pause",
    },
  });
  tl.add("start");
  tl.to( "#screenshot1", {
    opacity: 1,
    duration: 2,
    ease: "power2.out",
    onComplete: screenshotsAnim,
  }, "start");
}
const ease = "power2.out";
const screenshotsAnim = () => {
  const tl = gsap.timeline({
    repeat: -1,
    paused: true,
    scrollTrigger: {
      trigger: "#laptopTrigger",
      start: "50% bottom",
      end: "bottom top",
      markers: false,
      toggleActions: "play pause resume pause",
    },
  });
  tl.add("start", "+=2");
  // screenshot2
  tl.to( "#screenshot2", {
    opacity: 1,
    duration: 2,
    ease,
  }, "start");

  // screenshot4 and reset opacity of screenshot2
  tl.to( "#screenshot4", {
    opacity: 1,
    duration: 2,
    ease,
  }, "start+=4");
  tl.to( "#screenshot2", {
    opacity: 0,
    duration: 0.01,
    ease: "none",
  }, "start+=6");

  // screenshot5 and reset opacity of screenshot4
  tl.to( "#screenshot5", {
    opacity: 1,
    duration: 2,
    ease,
  }, "start+=8");
  tl.to( "#screenshot4", {
    opacity: 0,
    duration: 0.01,
    ease: "none",
  }, "start+=10");

  //end
  tl.to( ".screenshotsNotFirst", {
    opacity: 0,
    duration: 2,
    ease,
  }, "+=2");

}
const Laptop = () => {
  useEffect( () => {
    screenshotFadeIn();
    // screenshotsAnim();
  }, [] );

  return (
    <div
      css={ css`
        position: relative;
        overflow: hidden;
      `}
    >
      <div css={ css`
        position: absolute;
        top: 0;
        bottom: 0;
        ${'' /* border: 1px solid red; */}
        width: 100%;
        z-index: -1;
      `}>
        <LaptopBkg />
      </div>
      <div
        id="laptopTrigger"
        css={ css`
          ${'' /* border: 1px solid red; */}
          margin: 0rem auto 0rem;
          max-width: 90vw;
          width: ${CONTENT_WIDTH}px;
          ${'' /* max-height: 600px;
          height: calc( 100vh - 5rem ); */}
          overflow: hidden;
          @media (max-width: ${MIN_WIDTH}px) {
            width: auto;
          }
        `}
      >
        { ( showLaptop &&
          <svg viewBox="0 0 1083 625">
            <defs>
              {/* horizontal fade */}
              <linearGradient id="grad-horizontal" x1={0} y1={0} x2="100%" y2={0}>
                <stop offset={0} stopColor="white" stopOpacity={0} />
                <stop offset="12%" stopColor="white" stopOpacity={1} />
                <stop offset="88%" stopColor="white" stopOpacity={1} />
                <stop offset="100%" stopColor="white" stopOpacity={0} />
              </linearGradient>
              {/* vertical fade */}
              <linearGradient id="grad-vertical" x1={0} y1={0} x2={0} y2="100%">
                <stop offset={0} stopColor="white" stopOpacity={0} />
                <stop offset="5%" stopColor="white" stopOpacity={1} />
                <stop offset="95%" stopColor="white" stopOpacity={1} />
                <stop offset="100%" stopColor="white" stopOpacity={0} />
              </linearGradient>
            </defs>
            <rect y={30} width={1083} height={565} fill="url('#grad-horizontal')"
            />
            {/* <rect x={130} width={823} height={625} fill="url('#grad-vertical')"
            /> */}
            <image x={25} y={25} width={1033} height={575} xlinkHref={laptopImg} />
            {/* black laptop screen: */}
            <rect x={153} y={58}
              width={ 778 }
              height={ 439 }
              css={ css`fill: #000000;`}
            />

            <image x={153} y={58} width={778} xlinkHref={screenshot1}
              id="screenshot1"
              opacity={ 0 }
            />
            <image x={153} y={58} width={778} xlinkHref={screenshot2}
              id="screenshot2"
              className="screenshotsNotFirst"
              opacity={ 0 }
            />
            <image x={153} y={58} width={778} xlinkHref={screenshot4}
              id="screenshot4"
              className="screenshotsNotFirst"
              opacity={ 0 }
            />
            <image x={153} y={58} width={778} xlinkHref={screenshot5}
              id="screenshot5"
              className="screenshotsNotFirst"
              opacity={ 0 }
            />

          </svg> )
          ||
          <div css={css`height:100vh;`}
          >
            &nbsp;
          </div>
        }
        <div
          css={css`
            text-align: right;
            font-size: 12px;
            color: #555555;
          `}
        >
          *My actual laptop
        </div>

      </div>
    </div>
  );
}
export default Laptop;
