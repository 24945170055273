import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  aws,
  cisco,
  cssLogo,
  devops,
  docker,
  figma,
  gcloud,
  html,
  illustrator,
  js,
  kubernetes,
  linux,
  meteor,
  mongodb,
  mysql,
  node,
  perl1,
  photoshop,
  react1,
  react2,
  reactLogo,
  ubuntu,
} from '../images/svg/l';

const Aws = ({x,y}) => (
  <image x={x} y={y} width={200} href={ aws }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Cisco = ({x,y}) => (
  <image x={x} y={y} width={260} href={ cisco }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Css = ({x,y}) => (
  <image x={x} y={y} width={180} href={ cssLogo }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Devops = ({x,y}) => (
  <image x={x} y={y} width={340} href={ devops }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Docker = ({x,y}) => (
  <image x={x} y={y} width={270} href={ docker }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Figma = ({x,y}) => (
  <image x={x} y={y} width={140} href={ figma }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Gcloud = ({x,y}) => (
  <image x={x} y={y} width={270} href={ gcloud }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Html = ({x,y}) => (
  <image x={x} y={y} width={160} href={ html }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Illustrator = ({x,y}) => (
  <image x={x} y={y} width={210} href={ illustrator }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Js = ({x,y}) => (
  <image x={x} y={y} width={190} href={ js }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Kubernetes = ({x,y}) => (
  <image x={x} y={y} width={190} href={ kubernetes }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Linux = ({x,y}) => (
  <image x={x} y={y} width={190} href={ linux }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Meteor = ({x,y}) => (
  <image x={x} y={y - 20} width={180} href={ meteor }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Mongodb = ({x,y}) => (
  <image x={x} y={y} width={600} href={ mongodb }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Mysql = ({x,y}) => (
  <image x={x} y={y} width={300} href={ mysql }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Node = ({x,y}) => (
  <image x={x} y={y} width={400} href={ node }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const Perl1 = ({x,y}) => (
  <image x={x} y={y} width={320} href={ perl1 }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
);
const ReactLogo = ({x,y}) => (
  <image x={x} y={y} width={200} href={ react1 }
    opacity={ 0.3 } className="laptopBkgSvg"
  />
);
const Photoshop = ({x,y}) => (
  <image x={x} y={y} width={210} href={ photoshop }
    opacity={ 0.2 } className="laptopBkgSvg"
  />
)
/* ************************************* */
const bkgAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline({
    // repeat: 1,
    scrollTrigger: {
      trigger: ".laptopBkg",
      start: "top bottom",
      end: "bottom top",
      markers: false,
      toggleActions: "play pause resume pause",
    }
  });
  tl.add("start");
  tl.fromTo( ".laptopBkgGroup", {
    x: 100,
  }, {
    x: -2100,
    duration: 180,
    ease: "none",
  }, "start");
  tl.fromTo( ".laptopBkgGroup", {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 2,
    ease: "none",
  }, "start");
}
const LaptopBkg = () => {
  useEffect( () => {
    bkgAnimation();
  }, [] );
  return (
    <svg viewBox="0 0 2000 1000" className="laptopBkg">
      <g className="laptopBkgGroup">
        {/* 1 */}
        <ReactLogo x={0} y={0}/>
        <Aws x={280} y={70}/>
        <Html x={580} y={0}/>
        <Linux x={840} y={0}/>
        <Mysql x={1110} y={0}/>
        <Gcloud x={1480} y={0}/>
        <Docker x={1830} y={0}/>

        <ReactLogo x={0 + 2200} y={0}/>
        <Aws x={280 + 2200} y={70}/>
        <Html x={580 + 2200} y={0}/>
        <Linux x={840 + 2200} y={0}/>
        <Mysql x={1110 + 2200} y={0}/>
        <Gcloud x={1480 + 2200} y={0}/>
        <Docker x={1830 + 2200} y={0}/>

        {/* 2 */}
        <Css x={0} y={280}/>
        <Photoshop x={290} y={320}/>
        <Js x={620} y={320}/>
        <Mongodb x={900} y={350}/>
        <Illustrator x={1580} y={320}/>
        <Figma x={1920} y={320}/>

        <Css x={0 + 2200} y={280}/>
        <Photoshop x={290 + 2200} y={320}/>
        <Js x={620 + 2200} y={320}/>
        <Mongodb x={900 + 2200} y={350}/>
        <Illustrator x={1580 + 2200} y={320}/>
        <Figma x={1920 + 2200} y={320}/>

        {/* 3 */}
        <Kubernetes x={0} y={600}/>
        <Node x={280} y={600}/>
        <Devops x={760} y={600}/>
        <Cisco x={1180} y={600}/>
        <Perl1 x={1520} y={600}/>
        <Meteor x={1900} y={600}/>

        <Kubernetes x={0 + 2200} y={600}/>
        <Node x={280 + 2200} y={600}/>
        <Devops x={760 + 2200} y={600}/>
        <Cisco x={1180 + 2200} y={600}/>
        <Perl1 x={1520 + 2200} y={600}/>
        <Meteor x={1900 + 2200} y={600}/>
      </g>
    </svg>
  );
}
export default LaptopBkg;
