import React from 'react';
import { css } from '@emotion/react';
import jonImage from '../images/img/jon-spewak-c001715-4.png';
import left1 from '../images/img/blink-left-1.png';
import left2 from '../images/img/blink-left-2.png';
import left3 from '../images/img/blink-left-3.png';
import left4 from '../images/img/blink-left-4.png';
import left5 from '../images/img/blink-left-5.png';
import left6 from '../images/img/blink-left-6.png';
import left7 from '../images/img/blink-left-7.png';
import right1 from '../images/img/blink-right-1.png';
import right2 from '../images/img/blink-right-2.png';
import right3 from '../images/img/blink-right-3.png';
import right4 from '../images/img/blink-right-4.png';
import right5 from '../images/img/blink-right-5.png';
import right6 from '../images/img/blink-right-6.png';
import right7 from '../images/img/blink-right-7.png';

const leftX = 464;
const leftY = 279;
const rightX = 603;
const rightY = 263;

const Jon = () => {
  return (
    <svg viewBox="0 0 1260 1400" id="jonProfile">
      <image x={0} y={0} width={1260} height={1400} xlinkHref={jonImage} />
      <image className="eyes1"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left1} opacity={0}
      />
      <image className="eyes2"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left2} opacity={0}
      />
      <image className="eyes3"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left3} opacity={0}
      />
      <image className="eyes4"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left4} opacity={0}
      />
      <image className="eyes5"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left5} opacity={0}
      />
      <image className="eyes6"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left6} opacity={0}
      />
      <image className="eyes7"
        x={leftX} y={leftY} width={83} height={31} xlinkHref={left7} opacity={0}
      />
      <image className="eyes1"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right1} opacity={0}
      />
      <image className="eyes2"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right2} opacity={0}
      />
      <image className="eyes3"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right3} opacity={0}
      />
      <image className="eyes4"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right4} opacity={0}
      />
      <image className="eyes5"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right5} opacity={0}
      />
      <image className="eyes6"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right6} opacity={0}
      />
      <image className="eyes7"
        x={rightX} y={rightY} width={81} height={40} xlinkHref={right7} opacity={0}
      />
    </svg>
  )
}
export default Jon;
