import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONTENT_WIDTH, MIN_WIDTH } from '../functions/global';

const cell = css`width:33%;`;
const h1 = css`fill:#888888;font-size:70px;font-weight:700;`;
const h2 = css`fill:#006356;font-size:100px;font-weight:300;visibility:hidden;`;
const h3 = css`fill:#888888;font-size:60px;font-weight:700;`;
const h4 = css`fill:#666666;font-size:40px;font-weight:400;`;
const viewBoxHeight = 525;
const bkg = css`fill:#006356;`;

const yearsAnim = () => {
  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: "#years",
      markers: false,
      start: "80% bottom",
      end: "bottom top",
      toggleActions: "play pause resume pause",
    },
  });
  tl.add("start");
  tl.fromTo( ".frontend-num1", {
    y: -1200,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start");
  tl.fromTo( ".frontend-num2", {
    y: -100,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start");

  tl.fromTo( ".backend-num1", {
    y: -1200,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start+=0.2");
  tl.fromTo( ".backend-num2", {
    y: -200,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start+=0.4");

  tl.fromTo( ".devops-num1", {
    y: -1000,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start+=0.8");
  tl.fromTo( ".devops-num2", {
    y: -200,
    visibility: "visible",
  }, {
    y: 0,
    ease: "power2.out",
    duration: 2,
  }, "start+=0.6");
  // tl.to( ".devops-nums", {
  //   fill: "#006356",
  // });

}

const skillsAnim = () => {
  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: "#skillsTrigger",
      markers: false,
      start: "bottom bottom",
      end: "bottom top",
      toggleActions: "play pause resume pause",
    },
  });
  tl.add("start");
  tl.fromTo( ".skill", {
    y: "random(-50,50,-10)",
    scale: 0.1,
    x: 100,
    visibility: "hidden",
  }, {
    visibility: "visible",
    y: 0,
    x: 0,
    scale: 1,
    duration: 1,
    ease: "power3.out",
    stagger: {
      each: 0.15,
      grid: "auto",
      from: "center",
    }
  },"start");
}

const Experience = () => {
  useEffect( () => {
    yearsAnim();
    const browserRegEx = new RegExp("Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini", "i");
    if( ! browserRegEx.test(navigator.userAgent) ) {
      skillsAnim();
    }
  }, [] );
  return (
    <div
      css={ css`
        ${'' /* border: 1px solid red; */}
        margin: 0rem auto 0rem;
        max-width: 90vw;
        width: ${CONTENT_WIDTH}px;
        ${'' /* max-height: 600px;
        height: calc( 100vh - 5rem ); */}
      `}
    >
      <svg viewBox={`0 0 1082 ${viewBoxHeight}`}>
        {/* green backgrounds */}
        {/* <rect x={40} y={0} width={320} height={viewBoxHeight}
          css={bkg}
        /> */}

        <text className="frontend-num2 devops-nums"
          x={174} y={210} textAnchor="middle" css={h2}
        >1</text>
        { [2,1,0,9,8,7,6,5,4,3,2,1].map( (a,i) => {
          return (
            <text className="frontend-num1 devops-nums"
              key={"frontend1-"+i} x={218} y={210 + (i * 100)} textAnchor="middle" css={h2}
            >{ a }</text>
          );
        } ) }

        { [2,1].map( (a,i) => {
          return (
            <text className="backend-num2 devops-nums"
              key={"frontend2-"+i} x={523} y={210 + (i * 100)} textAnchor="middle" css={h2}
            >{ a }</text>
          )
        } ) }
        { [2,1,0,9,8,7,6,5,4,3,2,1].map( (a,i) => {
          return (
            <text className="backend-num1 devops-nums"
              key={"backend1-"+i} x={575} y={210 + (i * 100)} textAnchor="middle" css={h2}
            >{ a }</text>
          );
        } ) }

        { [2,1].map( (a,i) => {
          return (
            <text className="devops-num2 devops-nums"
              key={"frontend2-"+i} x={869} y={210 + (i * 100)} textAnchor="middle" css={h2}
            >{ a }</text>
          )
        } ) }
        { [0,9,8,7,6,5,4,3,2,1].map( (a,i) => {
          return (
            <text className="devops-num1 devops-nums"
              key={"backend1-"+i} x={921} y={210 + (i * 100)} textAnchor="middle" css={h2}
            >{ a }</text>
          );
        } ) }

        {/* <text x={570} y={200} textAnchor="middle" css={h2}
        >20</text> */}
        {/* <text x={880} y={200} textAnchor="middle" css={h2}
        >20</text> */}

        {/* filler boxes */}
        <rect x={0} y={0} width={1082} height={111}
          css={css`fill:#ffffff;`}
        />
        <rect x={0} y={228} width={1082} height={viewBoxHeight - 83}
          css={css`fill:#ffffff;`}
        />

        <text x={200} y={70} textAnchor="middle" css={h1}
        >frontend</text>
        <text x={549} y={70} textAnchor="middle" css={h1}
        >backend</text>
        <text x={895} y={70} textAnchor="middle" css={h1}
        >devops</text>

        <text x={200} y={308} textAnchor="middle" css={h3}
        >years</text>
        <text x={549} y={308} textAnchor="middle" css={h3}
          id="years"
        >years</text>
        <text x={895} y={308} textAnchor="middle" css={h3}
        >years</text>

        {/* <text x={220} y={200} textAnchor="middle" css={h2}
        >12</text> */}

        {/* outlines */}
        <rect x={50} y={0} width={300} height={viewBoxHeight}
          stroke="#006356" strokeWidth={2} css={css`fill:none`}
          rx={20}
        />
        <rect x={400} y={0} width={300} height={viewBoxHeight}
          stroke="#006356" strokeWidth={2} css={css`fill:none`}
          rx={20}
          id="skillsTrigger"
        />
        <rect x={750} y={0} width={290} height={viewBoxHeight}
          stroke="#006356" strokeWidth={2} css={css`fill:none`}
          rx={20}
        />

        {/* skills */}
        {/* frontend */}
        <text x={200} y={410} textAnchor="middle" css={h4} className="skill"
        >react&#8239;&middot;&#8239;html&#8239;&middot;&#8239;css</text>
        <text x={200} y={455} textAnchor="middle" css={h4} className="skill"
        >adobe&#8239;&middot;&#8239;figma</text>
        <text x={200} y={500} textAnchor="middle" css={h4} className="skill"
        >design thinking</text>

        {/* backend */}
        <text x={549} y={410} textAnchor="middle" css={h4} className="skill"
        >mysql&#8239;&middot;&#8239;mongo</text>
        <text x={549} y={455} textAnchor="middle" css={h4} className="skill"
        >cyber security</text>
        <text x={549} y={500} textAnchor="middle" css={h4} className="skill"
        >data protection</text>

        {/* devops */}
        <text x={895} y={410} textAnchor="middle" css={h4} className="skill"
        >ci/cd&#8239;&middot;&#8239;docker</text>
        <text x={895} y={455} textAnchor="middle" css={h4} className="skill"
        >agile&#8239;&middot;&#8239;scrum</text>
        <text x={895} y={500} textAnchor="middle" css={h4} className="skill"
        >cloud admin</text>
      </svg>
    </div>
  );
}
export default Experience;
