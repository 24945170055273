import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { css } from '@emotion/react';
import { CONTENT_WIDTH, MIN_WIDTH } from '../functions/global';

const codeIsMyArtAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(SplitText);
  const tl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: "#CodeIsMyArt",
      start: "top 80%",
      end: "bottom top",
      markers: false,
      toggleActions: "play pause resume pause",
    }
  });
  const mySplitText = new SplitText("#CodeIsMyArt", { type: "words,chars" });
  const chars = mySplitText.chars;
  tl.from(
    chars,
    {
      duration: 1,
      opacity: 0, scale: 0, y: 80, rotationX: 180,
      transformOrigin: "0% 50% -50%", ease: "back.out(1.7)",
      stagger: 0.03,
      // onComplete: () => mySplitText.revert(),
    },
  );
}
const CodeIsMyArt = () => {
  useEffect( () => {
    codeIsMyArtAnimation();
  }, []);
  return (
    <div id="CodeIsMyArt"
      css={css`
        text-align: center;
        font-size: 3rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 90vw;
        width: ${CONTENT_WIDTH}px;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      Code is my <span css={css`color:#006356;font-weight:500`}>art</span>.
    </div>

  )
}
export default CodeIsMyArt;
